export const UNLICENSED_POLICY = 'unlicensed'

// List of Policies that are unselectable
const unselectablePolicyIds = [12, 14, 16]

export const isUnselectablePolicy = id => !!unselectablePolicyIds.includes(id)

export const exchangePolicyIds = [15, 19, 20, 21, 22, 23, 24, 25, 33]
export const isExchangePolicy = id => exchangePolicyIds.includes(id)

const tenantLevelSettingPolicyTypes = [12, 14, 16]
export const isTenantLevelSettingPolicyType = policyTypeId =>
  tenantLevelSettingPolicyTypes.includes(policyTypeId)

const policyTypesWithOnlyOverwriteExisting = [2, 9, 17, 18]
export const isPolicyTypeWithOnlyOverwriteExisting = policyTypeId =>
  policyTypesWithOnlyOverwriteExisting.includes(policyTypeId)

export const isPolicyTypeCanBeDisabled = policyTypeId =>
  policyTypeId === 1 || isExchangePolicy(policyTypeId)
