import { useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { v4 as uuid } from 'uuid'
import { arrayOf, func, number, shape, string } from 'prop-types'
import AlignmentLight from './AlignmentLight'

const AlignmentNestedSimilarPolicies = ({
  parentPolicyId,
  similarPolicies,
  onRenamePolicy,
  alignedThreshold,
  semiAlignedThreshold,
}) => {
  const [openPolicyIds, setOpenPolicyIds] = useState([])
  const { policyDataDiff } = similarPolicies

  const policyDiff = useMemo(() => {
    // I *THINK* this is trying to filter out itself from the list even though I don't see it anyway
    const output =
      policyDataDiff?.filter(p => p.policyId !== parentPolicyId) ?? []
    output.sort((a, b) => b.matchPercentage - a.matchPercentage)
    return output
  }, [parentPolicyId, policyDataDiff])

  const toggleOpenPolicy = policyId => {
    if (openPolicyIds.includes(policyId)) {
      setOpenPolicyIds(ids => ids.filter(id => id !== policyId))
    } else {
      setOpenPolicyIds(ids => [...ids, policyId])
    }
  }

  const buildPolicyDescription = policy => {
    const { diff } = policy

    return (
      <div className='text-md'>
        <b>Data deviation from baseline:</b>
        <br />
        <div>
          {diff?.map(diffProperty => (
            <div key={uuid()} className='text-sm mb-2 mt-2'>
              <div className='mb-1 break-words'>
                <b>{diffProperty.path}</b>
              </div>
              <div className='flex mb-1 ml-2'>
                <b className='opacity-75 w-[80px] min-w-[80px]'>Baseline: </b>
                <div className='break-all'>
                  {JSON.stringify(diffProperty.baselineValue)}
                </div>
              </div>
              <div className='flex mb-4 ml-2'>
                <b className='opacity-75 w-[80px] min-w-[80px]'>Customer:</b>
                <div className='break-all'>
                  {JSON.stringify(diffProperty.subjectValue)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  return (
    <div>
      <b>Similar Policies</b>
      {policyDiff.length === 0 ? (
        <div className='mt-2'>No similar policies found</div>
      ) : null}
      {policyDiff.map(policy => {
        const { policyId, policyName, matchPercentage } = policy
        const collapsed = !openPolicyIds.includes(policyId)

        return (
          <div className='mt-2 light-cyan-bg rounded-md' key={policyId}>
            <div className='flex justify-between items-center p-2'>
              {policyName}
              <div className='flex justify-between items-center rounded-md'>
                <span className='mr-4 ml-4 w-[60px]'>
                  {matchPercentage.toFixed(2)}%
                </span>
                <AlignmentLight
                  score={matchPercentage}
                  alignedThreshold={alignedThreshold}
                  semiAlignedThreshold={semiAlignedThreshold}
                />
                <button
                  type='button'
                  className='btn cyan-btn text-xs ml-4 whitespace-nowrap'
                  onClick={() => onRenamePolicy(policy)}
                >
                  Rename to Baseline
                </button>
                <FontAwesomeIcon
                  className='ml-4 mr-2'
                  icon={collapsed ? faChevronDown : faChevronUp}
                  onClick={() => toggleOpenPolicy(policyId)}
                />
              </div>
            </div>
            <div
              className={`p-2 alignment-result-description ${collapsed ? ' hidden' : ''}`}
            >
              {buildPolicyDescription(policy)}
            </div>
          </div>
        )
      })}
    </div>
  )
}

AlignmentNestedSimilarPolicies.defaultProps = {
  similarPolicies: {
    policyDataDiff: [],
  },
}

AlignmentNestedSimilarPolicies.propTypes = {
  parentPolicyId: number.isRequired,
  similarPolicies: shape({
    policyDataDiff: arrayOf(
      shape({
        policyId: number.isRequired,
        policyName: string.isRequired,
        matchPercentage: number.isRequired,
      })
    ),
  }),
  onRenamePolicy: func.isRequired,
  alignedThreshold: number.isRequired,
  semiAlignedThreshold: number.isRequired,
}

export default AlignmentNestedSimilarPolicies
