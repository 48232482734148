import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { IntercomProvider } from 'react-use-intercom'
import Axios from 'axios'
import { toast } from 'react-toastify'
import { ConfigCatProvider } from 'configcat-react'
import App from './App'
import InsightsErrorBoundary from './components/InsightsErrorBoundary/InsightsErrorBoundary'

const reactPlugin = new ReactPlugin()

const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
    disableTelemetry: window.location.hostname === 'localhost',
  },
})
appInsights.loadAppInsights()

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID

Axios.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status === 401) {
      toast.info('Your session has expired. Please log back in.')
      if (!error?.request.responseURL.includes('/login')) {
        setTimeout(() => {
          window.location.href = '/login'
        }, 2000)
      }
    }
    return Promise.reject(error)
  }
)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <IntercomProvider appId={INTERCOM_APP_ID}>
        <InsightsErrorBoundary appInsights={appInsights}>
          <ConfigCatProvider sdkKey={process.env.REACT_APP_CONFIG_CAT_KEY}>
            <App />
          </ConfigCatProvider>
        </InsightsErrorBoundary>
      </IntercomProvider>
    </React.StrictMode>
  </BrowserRouter>
)
