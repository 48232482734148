import { useState } from 'react'
import PropTypes, { array, bool, object, objectOf } from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMinusCircle,
  faPlusCircle,
  faRotate,
} from '@fortawesome/free-solid-svg-icons'

import PolicyViewerChildren from './PolicyViewerChildren'
import DeletePolicyPopup from './DeletePolicyPopup'
import { UNLICENSED_POLICY } from '../../utils/Policy'
import Alerts from '../alerts/Alerts'

const PolicyViewer = ({
  data,
  checkedItems,
  showChildren,
  handleParentCheckboxChange,
  handleChildCheckboxChange,
  handleSelectAll,
  handleDropdownToggle,
  policyRequest,
  selectedTargetTenant,
  togglePolicyNotification,
  setTogglePolicyNotification,
  selectedObjects,
  refreshPolicies,
  resetDropdowns,
}) => {
  const [openPopup, setOpenPopup] = useState(false)

  const [currentPolicy, setCurrentPolicy] = useState({ policyData: '' })

  const { clientTenantId, isSharedBaseline, tenantFriendlyName } =
    selectedTargetTenant

  if (!data || Object.keys(data).length === 0) {
    return <h4 className='my-[30px]'>No policies available</h4>
  }

  // Delete policy popup functions
  const handleOpenPopUp = policyData => {
    setCurrentPolicy(policyData)
    setOpenPopup(true)
  }

  const handleClosePopUp = () => {
    setOpenPopup(false)
  }

  const closePopUpRefreshPolicies = selectedTargetTenant => {
    policyRequest(selectedTargetTenant)
    setOpenPopup(false)
  }

  return (
    <>
      {selectedTargetTenant ? (
        <div className='select-tenant-heading'>
          <h4 className='mr-1'>Selected Tenant: </h4>
          <p>{tenantFriendlyName}</p>
        </div>
      ) : (
        <div className='select-tenant-heading'>
          <h4 className='mr-1'>Select Tenant</h4>
        </div>
      )}
      <div className='flex justify-between items-center mb-[16px] select-policies mt-3'>
        <h4>Select policies to Explore</h4>
        <div className='select-policy-btns'>
          <button
            type='button'
            onClick={handleSelectAll}
            disabled={isSharedBaseline}
            className='btn navy-btn mr-2'
          >
            Select All
          </button>
          <button
            type='button'
            className={`btn text-white ${selectedObjects.length === 0 ? 'bg-gray-500 opacity-100' : 'bg-red-800 text-white'}`}
            disabled={selectedObjects.length === 0}
            onClick={() => handleOpenPopUp()}
          >
            Delete Selected
          </button>
          <button
            type='button'
            className='btn cyan-btn refresh-policies ml-2'
            onClick={() => refreshPolicies()}
          >
            <FontAwesomeIcon icon={faRotate} />
            &nbsp;&nbsp;Refresh
          </button>
        </div>
      </div>
      {togglePolicyNotification && (
        <Alerts title='Please note' alert='info-cyan' margin='my-6'>
          <p>
            Policy state should update shortly. Please refresh in a few moments
            to verify your choices have updated.
          </p>
        </Alerts>
      )}
      <div className='nested-checkboxes'>
        {Object.entries(data).map(([parentType, children]) => {
          const isUnlicensed =
            children?.length > 0 && children[0].id === UNLICENSED_POLICY

          return (
            <div key={parentType} className='parent-checkbox-container'>
              <div className='parent-checkbox'>
                <div
                  className={`parent-check-label ${isUnlicensed ? 'opacity-60' : ''}`}
                >
                  {parentType !== 'Entra/AAD Settings' &&
                    parentType !== 'Sharepoint Settings' &&
                    parentType !== 'Organisation Settings' &&
                    !isSharedBaseline && (
                      <label htmlFor='select' className='round'>
                        <input
                          id='select'
                          type='checkbox'
                          disabled={isUnlicensed}
                          name={parentType}
                          checked={!!checkedItems[parentType]}
                          onChange={handleParentCheckboxChange}
                        />
                      </label>
                    )}
                  &nbsp;&nbsp;{parentType}
                </div>
                <button
                  type='button'
                  onClick={() => handleDropdownToggle(parentType)}
                >
                  {showChildren[parentType] ? (
                    <FontAwesomeIcon icon={faMinusCircle} />
                  ) : (
                    <FontAwesomeIcon icon={faPlusCircle} />
                  )}
                </button>
              </div>
              {showChildren[parentType] && (
                <PolicyViewerChildren
                  nestedChildPolicies={children}
                  parentType={parentType}
                  selectedTargetTenant={selectedTargetTenant}
                  handleChildCheckboxChange={handleChildCheckboxChange}
                  setTogglePolicyNotification={setTogglePolicyNotification}
                  checkedItems={checkedItems}
                  isUnlicensed={isUnlicensed}
                />
              )}
            </div>
          )
        })}
      </div>

      {openPopup === true && (
        <DeletePolicyPopup
          handleClosePopUp={handleClosePopUp}
          closePopUpRefreshPolicies={closePopUpRefreshPolicies}
          policy={currentPolicy}
          policies={selectedObjects}
          clientTenantId={clientTenantId}
          selectedObjects={selectedObjects}
          refreshPolicies={refreshPolicies}
          resetDropdowns={resetDropdowns}
          saveOverride={false}
        />
      )}
    </>
  )
}

PolicyViewer.defaultProps = {
  togglePolicyNotification: false,
  policyRequest: () => {},
}

PolicyViewer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: object.isRequired,
  checkedItems: objectOf(bool).isRequired,
  showChildren: objectOf(bool).isRequired,
  handleParentCheckboxChange: PropTypes.func.isRequired,
  handleChildCheckboxChange: PropTypes.func.isRequired,
  handleSelectAll: PropTypes.func.isRequired,
  handleDropdownToggle: PropTypes.func.isRequired,
  policyRequest: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  selectedTargetTenant: object.isRequired,
  togglePolicyNotification: PropTypes.bool,
  setTogglePolicyNotification: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedObjects: array.isRequired,
  refreshPolicies: PropTypes.func.isRequired,
  resetDropdowns: PropTypes.func.isRequired,
}

export default PolicyViewer
