import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-loading-skeleton/dist/skeleton.css'
import { v4 as uuidv4 } from 'uuid'
import Axios from 'axios'
import PolicyViewer from '../components/Policies/PolicyViewer'
import PolicyPickerSkeleton from '../components/LoadingSkeletons/PolicyPickerSkeleton'
import { UNLICENSED_POLICY, isUnselectablePolicy } from '../utils/Policy'
import IsReadOnlyPolicy from '../utils/IsReadOnlyPolicy'
import useGetTenants from '../components/SharedBaselines/api/useGetTenants'
import SelectedItems from '../components/ListTables/SelectedItems'
import ListTable from '../components/ListTables/ListTable'
import Alerts from '../components/alerts/Alerts'
import BackupTagsCell from '../components/BackupTagsCell'

const TenantExplorer = () => {
  // context API loading state
  const [loadingState, setLoadingState] = useState(true)
  // Form Logic
  const formArray = [1, 2]
  const [formNo, setFormNo] = useState(formArray[0])
  // Target tenants
  const [selectedSourceTenant, setSelectedSourceTenant] = useState([])
  // Policy picker
  const [checkedItems, setCheckedItems] = useState({})
  const [selectedObjects, setSelectedObjects] = useState([])
  const [data, setData] = useState([])
  const [showChildren, setShowChildren] = useState({})
  const [selectAll, setSelectAll] = useState(false)
  const [openDropdowns, setOpenDropdowns] = useState([])
  const [togglePolicyNotification, setTogglePolicyNotification] =
    useState(false)
  const [abortController, setAbortController] = useState(null)
  // Parse data for posting
  const [formResponses, setFormResponses] = useState([])

  useEffect(() => {
    if (formNo === 1) {
      setFormResponses([])
    }
  }, [formNo])

  // tells us whether a tenant is a shared baseline tenant
  const {
    data: tenants,
    isLoading: isTenantsLoading,
    isError: isTenantsError,
    isSuccess,
  } = useGetTenants()

  const checkParentCheckbox = (parentType, updatedCheckedItems) => {
    const allChildrenChecked = data[parentType]
      .filter(childPolicy => !IsReadOnlyPolicy(childPolicy))
      .map(child => updatedCheckedItems[child.id.toString()])
    // TODO - ESLINT FIX ME
    // eslint-disable-next-line no-param-reassign
    updatedCheckedItems[parentType] = allChildrenChecked.every(Boolean)
    setCheckedItems(updatedCheckedItems)
  }
  const handleParentCheckboxChange = e => {
    const { name, checked } = e.target
    const updatedCheckedItems = { ...checkedItems }
    const updatedShowChildren = { ...showChildren }
    updatedCheckedItems[name] = checked
    updatedShowChildren[name] = checked
    if (checked) {
      data[name].forEach(child => {
        if (!IsReadOnlyPolicy(child)) {
          updatedCheckedItems[child.id] = true
          if (!selectedObjects.some(obj => obj.id === child.id)) {
            setSelectedObjects(prevSelectedObjects => [
              ...prevSelectedObjects,
              child,
            ])
          }
        }
      })
    } else {
      data[name].forEach(child => {
        updatedCheckedItems[child.id] = false
        setSelectedObjects(prevSelectedObjects =>
          prevSelectedObjects.filter(obj => obj.id !== child.id)
        )
      })
    }
    setCheckedItems(updatedCheckedItems)
    setShowChildren(updatedShowChildren)
    checkParentCheckbox(name, updatedCheckedItems)
    const containsFalse = Object.values(updatedCheckedItems).includes(false)
    if (containsFalse === true) {
      setSelectAll(false)
    }
  }
  const handleChildCheckboxChange = e => {
    const { name, checked } = e.target
    const updatedCheckedItems = { ...checkedItems }
    updatedCheckedItems[name] = checked
    const parentType = Object.keys(data).find(type =>
      data[type].some(obj => obj.id.toString() === name)
    )
    if (checked) {
      const selectedObject = data[parentType].find(
        obj => obj.id.toString() === name
      )
      setSelectedObjects(prevSelectedObjects => [
        ...prevSelectedObjects,
        selectedObject,
      ])
    } else {
      setSelectedObjects(prevSelectedObjects =>
        prevSelectedObjects.filter(obj => obj.id.toString() !== name)
      )
      // If at least one child is deselected, set the parent checkbox to false
      updatedCheckedItems[parentType] = false
    }
    // Check the parent checkbox if all children are selected
    checkParentCheckbox(parentType, updatedCheckedItems)
    const containsFalse = Object.values(updatedCheckedItems).includes(false)
    if (containsFalse === true) {
      setSelectAll(false)
    } else {
      setSelectAll(true)
    }
    setCheckedItems(updatedCheckedItems)
  }
  const handleSelectAll = () => {
    const updatedCheckedItems = {}
    const updatedSelectedObjects = []
    if (!selectAll) {
      Object.entries(data).forEach(([parentType, children]) => {
        // Any unlicensed policies shouldn't be selectable
        if (
          children[0].id === UNLICENSED_POLICY ||
          isUnselectablePolicy(children[0].PolicyTypeId)
        )
          return
        updatedCheckedItems[parentType] = true
        children.forEach(child => {
          if (!IsReadOnlyPolicy(child)) {
            updatedCheckedItems[child.id] = true
            if (!updatedSelectedObjects.some(obj => obj.id === child.id)) {
              updatedSelectedObjects.push(child)
            }
          }
        })
      })
      setShowChildren({}) // Hide all dropdowns when select all is checked
    } else {
      // Uncheck all checkboxes when select all is unchecked
      Object.keys(updatedCheckedItems).forEach(key => {
        updatedCheckedItems[key] = false
      })
    }
    setCheckedItems(updatedCheckedItems)
    setSelectedObjects(updatedSelectedObjects)
    setSelectAll(!selectAll)
  }
  const handleDropdownToggle = parentType => {
    if (parentType === 'selectAll') {
      return // Ignore dropdown toggle triggered by "Select All" checkbox
    }
    const updatedShowChildren = { ...showChildren }
    updatedShowChildren[parentType] = !showChildren[parentType]
    setShowChildren(updatedShowChildren)
    if (updatedShowChildren[parentType]) {
      setOpenDropdowns([...openDropdowns, parentType])
    } else {
      setOpenDropdowns(openDropdowns.filter(type => type !== parentType))
    }
  }
  const resetDropdowns = () => {
    setShowChildren({})
    setOpenDropdowns([])
    setSelectedObjects([])
    setTogglePolicyNotification(true)
  }
  const policyRequest = async selectedSourceTenant => {
    const ctId = +selectedSourceTenant[0].clientTenantId
    setLoadingState(true)
    const controller = new AbortController()
    setAbortController(controller)
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/list-policies`,
        {
          ctId,
        },
        { signal: controller.signal }
      )
      setData(response.data)
      setLoadingState(false)
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          toast.info('Your session has expired. Please log back in.')
          setTimeout(() => {
            window.location.href = '/'
          }, 2000)
        }
      } else {
        toast.error('Something went wrong. Please try again.')
      }
    } finally {
      setLoadingState(false)
    }
  }
  // Refresh policies
  const refreshPolicies = () => {
    policyRequest(selectedSourceTenant)
    setTogglePolicyNotification(false)
  }
  // Timeline navigation
  const next = () => {
    if (formNo === 1) {
      if (!selectedSourceTenant) {
        toast.error('Please select a source tenant')
        return
      }
      const stepOneRes = { step: formNo, response: [selectedSourceTenant] }
      // Find the existing response for step 1 in the formResponses array
      const existingResponseIndex = formResponses.findIndex(
        response => response.step === formNo
      )
      if (existingResponseIndex !== -1) {
        // If an existing response exists, replace it with the latest response
        const updatedResponses = [...formResponses]
        updatedResponses[existingResponseIndex] = stepOneRes
        setFormResponses(updatedResponses)
      } else {
        // Otherwise, add the response to the array
        setFormResponses(prevFormResponses => [
          ...prevFormResponses,
          stepOneRes,
        ])
      }
      policyRequest(selectedSourceTenant)
      setFormNo(formNo + 1)
    }
  }
  const pre = () => {
    if (abortController) {
      abortController.abort()
    }
    if (formNo === 2) {
      // Reset form responses and policy picker if the user goes back to the first stage
      setFormResponses([])
      setOpenDropdowns([])
      setSelectedObjects([])
      setCheckedItems({})
      setSelectAll(false)
    }
    setFormNo(formNo - 1)
  }
  const tenantsConfig = {
    columns: [
      { header: 'Tenant Name', accessor: 'tenantFriendlyName' },
      {
        header: 'Tags',
        accessor: 'tags',
        cell: BackupTagsCell,
      },
    ],
    data: tenants?.data,
  }
  // Timeline step text
  const stepH1 = 'Select Tenant'
  const stepH2 = 'Select Policy'
  const stepP1 = 'Select Tenant'
  const stepP2 = 'Select Policy'
  return (
    <div className='ui-panel policy-explorer-journey dark:bg-slate-800'>
      <div className='heading'>
        <h2>Tenant Explorer</h2>
        <p>Explore Policies in your chosen Tenant</p>
      </div>
      <div className='action-body'>
        <div className='progress-bar'>
          {formArray.map((v, i) => (
            <div key={uuidv4()}>
              <div className='flex'>
                <div className='flex'>
                  <div
                    className={`progress-number progress-number-${v} ${
                      formNo - 1 === i ||
                      formNo - 1 === i + 1 ||
                      formNo - 1 === i + 2 ||
                      formNo === formArray.length
                        ? 'cyan-bg text-white'
                        : 'bg-slate-300'
                    }`}
                  >
                    {v}
                  </div>
                  <div className='progress-text'>
                    <p className='navy-text'>
                      <strong>
                        {(v === 1 && stepH1) || (v === 2 && stepH2)}
                      </strong>
                    </p>
                    <p className='text-gray-400'>
                      {(v === 1 && stepP1) || (v === 2 && stepP2)}
                    </p>
                  </div>
                </div>
              </div>
              {i !== formArray.length - 1 && (
                <div className='progress-bar-link' />
              )}
            </div>
          ))}
        </div>
        <div className='action-body-form'>
          {formNo === 1
            ? !isTenantsLoading &&
              isSuccess && (
                <>
                  <SelectedItems
                    title={
                      selectedSourceTenant.length > 0
                        ? 'Selected tenant:'
                        : 'No tenants selected'
                    }
                    selectedListItems={selectedSourceTenant.map(
                      tenant => tenant.tenantFriendlyName
                    )}
                  />
                  <ListTable
                    selectedListItems={selectedSourceTenant}
                    setSelectedListItems={setSelectedSourceTenant}
                    uniqueKey='clientTenantId'
                    config={tenantsConfig}
                    enableMultiRowSelection={false}
                    enableRowSelection
                  />
                  <div className='mt-6 gap-3 flex'>
                    <button
                      type='button'
                      onClick={next}
                      disabled={selectedSourceTenant.length === 0}
                      className='btn cyan-btn'
                    >
                      Next
                    </button>
                  </div>
                </>
              )
            : isTenantsError && (
                <Alerts
                  alert='error'
                  title={isTenantsError.response.data.message}
                  margin='mb-4'
                >
                  {isTenantsError.response.data.errors?.map(error => (
                    <ul>
                      <li>{error}</li>
                    </ul>
                  ))}
                </Alerts>
              )}
          {formNo === 2 && (
            <>
              {loadingState ? (
                <PolicyPickerSkeleton loadingTitle='Loading policies from Microsoft 365...' />
              ) : (
                <PolicyViewer
                  data={data}
                  checkedItems={checkedItems}
                  showChildren={showChildren}
                  selectAll={selectAll}
                  handleParentCheckboxChange={handleParentCheckboxChange}
                  handleChildCheckboxChange={handleChildCheckboxChange}
                  handleSelectAll={handleSelectAll}
                  handleDropdownToggle={handleDropdownToggle}
                  selectedTargetTenant={selectedSourceTenant[0]}
                  selectedObjects={selectedObjects}
                  refreshPolicies={refreshPolicies}
                  resetDropdowns={resetDropdowns}
                  togglePolicyNotification={togglePolicyNotification}
                  setTogglePolicyNotification={setTogglePolicyNotification}
                />
              )}
              <div className='mt-6 gap-3 flex'>
                <button type='button' onClick={pre} className='btn navy-btn'>
                  Previous
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
export default TenantExplorer
